
  import { mapState } from 'vuex'
  export default {
    props: {
      blok: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      ...mapState(['channel']),
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      }
    }
  }
